import { da, de, enGB, fi, fr, nb, sv } from 'date-fns/locale';
import {
	addDays as dateAddDays,
	formatDistanceStrict as dateFormatDistanceStrictInternal,
	format as dateFormatInternal,
	startOfWeek as dateStartOfWeek
} from 'date-fns';

// Set language
let localeFile = sv;
if (typeof window !== 'undefined') {
	localeFile = getLocale(window?.js_params?.module?.LanguageSelector?.languageSelected || 'sv');
}

export function setLocale (locale) {
	localeFile = getLocale(locale);
}

function getLocale (locale) {
	switch (locale) {
		case 'en':
			return enGB;
		case 'sv':
			return sv;
		case 'da':
			return da;
		case 'de':
			return de;
		case 'no':
			return nb;
		case 'fr':
			return fr;
		case 'fi':
			return fi;
		default:
			return enGB;
	}
	// Dynamically import dates
	// switch (locale) {
	// 	case 'en':
	// 		return import('date-fns/locale/en-GB/index.js');
	// 	case 'sv':
	// 		return import('date-fns/locale/sv/index.js');
	// 	case 'da':
	// 		return import('date-fns/locale/da/index.js');
	// 	case 'de':
	// 		return import('date-fns/locale/de/index.js');
	// 	case 'no':
	// 		return import('date-fns/locale/nb/index.js');
	// 	case 'fr':
	// 		return import('date-fns/locale/fr/index.js');
	// 	default:
	// 		return import('date-fns/locale/en-GB/index.js');
	// }
}

export function dateFormat (date, formatStyle) {
	return removeDotFromString(dateFormatInternal(date, formatStyle, { locale: localeFile }));
}

export function dateFormatYMD (date) {
	return removeDotFromString(dateFormat(date, 'yyyy-MM-dd'));
}

export function dateFormatDistanceStrict (date, baseDate, options) {
	return removeDotFromString(dateFormatDistanceStrictInternal(date, baseDate, { ...options, locale: localeFile }));
}

export const dateShortWeekDaysArray = (function () {
	const firstDayOfWeek = dateStartOfWeek(new Date());
	return Array.from(Array(7)).map((e, i) => removeDotFromString(dateFormat(dateAddDays(firstDayOfWeek, i), 'E')));
}());


export {
	add as dateAdd,
	addDays as dateAddDays,
	addMinutes as dateAddMinutes,
	addMonths as dateAddMonths,
	addWeeks as dateAddWeeks,
	addHours as dateAddHours,
	differenceInDays as dateDifferenceInDays,
	formatDistance as dateFormatDistance,
	getDate as dateGetDate,
	getDay as dateGetDay,
	getHours as dateGetHours,
	getMinutes as dateGetMinutes,
	getMonth as dateGetMonth,
	getSeconds as dateGetSeconds,
	getYear as dateGetYear,
	isAfter as dateIsAfter,
	isBefore as dateIsBefore,
	isPast as dateIsPast,
	isSameDay as dateIsSameDay,
	isThisHour as dateIsThisHour,
	isToday as dateIsToday,
	isWeekend as dateIsWeekend,
	parseISO as dateParseISO,
	set as dateSet,
	subDays as dateSubDays,
	subHours as dateSubHours,
	startOfDay as dateStartOfDay
} from 'date-fns';


/**
 *
 * @param {String} str
 * @returns {String}
 */
function removeDotFromString (str) {
	return str.replace(/\./g, '');
}
