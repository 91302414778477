import { hideElement, l, lreplace, showElement } from '@js/utils/toolbox';

const isBrowser = typeof window !== 'undefined';

/**
 * @param {TooltipMessage} item
 * @param {String} type
 * @param {String} display
 * @returns {Toast} object
 * This class displays a toast
 */
export class Toast {
	constructor (TooltipMessage, type = '', display = '') {
		if (isBrowser) {
			this.message = TooltipMessage;
			this.type = type;
			this.display = display;
			this.toastElement = document?.createElement('div');
			this.toastElement.innerHTML = `
<div class="toast-container">
	<div class="toast-text"></div>
</div>
<div class="toast-close icon icon-circle-cross" data-action="toast-dismiss"></div>
			`;
		}
	}

	assign () {
		this.toastElement.className = 'toast';

		if (!['top', 'bot', 'stays'].includes(this.display)) {
			console.debug('Invalid display type, defaulting to top', this.display);
			this.display = 'top';
		}

		this.toastElement.classList.add(this.display);

		// success, warning, error, alert, info, dark
		this.toastElement.classList.add(this.type);
		let toastText = `
${l(this.message.text)} 
${this.message.options?.link ? `<a href=${this.message.options?.linkUrl} title=${this.message.options?.linkText}>${this.message.options?.linkText}</a>` : ``}
		`;

		// We love complexity
		if (this.message.replaceParams) {
			toastText = lreplace(toastText, this.message.replaceParams);
		}

		this.toastElement.querySelector('.toast-text').innerHTML = toastText;
		document.body.appendChild(this.toastElement);
	}

	show () {
		showElement(this.toastElement);
		document.body.classList.add('toast-showing');

		// this should add hiding class
		if (this.display !== 'stays') {
			setTimeout(() => {
				this.hide();
			}, 3000);
		}
	}

	hide () {
		hideElement(this.toastElement);
		document.body.classList.remove('toast-showing');
	}
}
